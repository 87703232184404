<template>
  <div class="row">
    <div class="col-12 text-center g" v-if="loading">
        <img
        :src="require('@/assets/images/loading.svg')"
        style="width: 150px; height: 150px"
        alt=""
        />
        <br><br>
    </div>
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4>المزودين</h4>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <th>
                            المزود
                        </th>
                        <th>
                            العدد
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(p, index) in providers" :key="index * 2">
                            <td>
                                {{ p.name }}
                            </td>
                            <td>
                                {{ p.value }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4>الانواع</h4>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <th>
                            النوع
                        </th>
                        <th>
                            العدد
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(p, index) in obj" :key="index * 2">
                            <td>
                                {{ p.name }}
                            </td>
                            <td>
                                {{ p.value }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            providers: [],
            obj: [],
            user: JSON.parse(localStorage.getItem("user")),
        }
    },
    created(){
        var g = this;
        $.post(marketing + "/stats", {
        jwt: this.user.jwt,
        }).then(function(a){
            g.providers = []
            for (const [key, value] of Object.entries(a.providers)) {
                g.providers.push({
                    name: key,
                    value: value
                })
            }
            function compare( a, b ) {
                if ( a.value < b.value ){
                    return 1;
                }
                if ( a.value > b.value ){
                    return -1;
                }
                return 0;
            }
            g.providers.sort( compare );
            for (const [key, value] of Object.entries(a.obj)) {
                g.obj.push({
                    name: key,
                    value: value
                })
            }
            g.obj.sort( compare );
        g.loading = false
    })
    }
}
</script>

<style>

</style>